.items-table td {
    vertical-align: middle;
}

.items-table td:nth-of-type(1),
.items-table th:nth-of-type(1) {
    text-align: left;
}

.items-table .description {
    color: rgba(0,0,0,.5);
    font-size: 0.9em;
    line-height: .9em;
}