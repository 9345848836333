body {
    position: relative;
}

/***********************
NAVBAR
***********************/
#sidebar {
    width: 300px;
    max-width: 90vw;
    position: fixed;
    top: 0;
    bottom: 60px;
    left: -301px;

    color: #fff;
    transition: all 0.4s cubic-bezier(0.29, 0.57, 0.2, 1.4) 0s;
    padding-bottom: 60px;
    z-index: 1030;

    background: #ed213a;
    background: -webkit-linear-gradient(to right, #93291e, #ed213a);
    background: linear-gradient(to right, #93291e, #ed213a);
}

@media (max-width: 767px) {
    #sidebar.toggled {
        left: 0;
    }
}

@media (min-width: 768px) {
    #sidebar {
        left: 0;
        bottom: 0;
    }
    body.sidenav-toggled #sidebar {
        width: 60px;
    }
}

/*Link container*/
#sidebar ul.link-container {
    height: calc(100vh - 160px - 1.5rem - 60px);
    transition: height 0.4s ease-in-out;
    overflow-y: scroll;
    flex-wrap: nowrap;
}

@media (min-width: 768px) {
    #sidebar ul.link-container {
        height: calc(100vh - 160px - 70px - 1.5rem);
    }
    body.sidenav-toggled #sidebar ul.link-container {
        height: calc(100vh - 93px - 70px - 1.5rem);
    }
}

/*profile*/

#sidebar .profile {
    padding: 3rem 1.5rem;
    margin-bottom: 1.5rem;
    transition: height 0.4s ease-in-out;
    height: 160px;
    white-space: nowrap;
}

@media (min-width: 768px) {
    body.sidenav-toggled #sidebar .profile .media-body {
        display: none;
    }

    body.sidenav-toggled #sidebar .profile {
        padding: 1.5rem 0rem !important;
        justify-content: center;
        height: 100px;
    }
    body.sidenav-toggled #sidebar .profile img {
        width: 45px;
    }
}

/*Nav items & Links*/

#sidebar .nav-item a.nav-link {
    color: #fff;
    overflow-x: hidden;
    white-space: nowrap !important;
}

#sidebar .nav-item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-left: 3px solid transparent;
}

#sidebar .nav-item:hover,
#sidebar .nav-item.active,
#sidebar .nav-item:focus {
    background-color: #fff;
    border-left-color: var(--hw);
}
#sidebar .nav-item:hover a,
#sidebar .nav-item:focus a,
#sidebar .nav-item.active a {
    color: var(--hw);
}

#sidebar .nav-item .nav-text {
    margin-left: 0.75rem;
    white-space: nowrap;
}

@media (min-width: 768px) {
    body.sidenav-toggled #sidebar .nav-item .nav-text {
        display: none;
    }
}

/*nav toggler */

#sidebar .nav-toggler {
    display: none;
}

@media (min-width: 768px) {
    #sidebar .nav-toggler {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
        bottom: 0;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    body.sidenav-toggled #sidebar .nav-toggler {
        transform: rotate(180deg);
    }
}

/***********************
MENU BAR
***********************/

nav.nav-pills.menubar {
    background: #ed213a;
    background: -webkit-linear-gradient(to right, #93291e, #ed213a);
    background: linear-gradient(to right, #93291e, #ed213a);

    height: 60px;
    align-items: center;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.2), 0 -6px 10px 0 rgba(0, 0, 0, 0.2);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-bottom: 1rem;
    padding-top: 0;
}

nav.nav-pills.menubar > a {
    color: rgba(255, 255, 255, 0.6);
    font-size: 23px;
    border-top: 4px solid transparent;
}

@media (max-width: 400px) {
    nav.nav-pills.menubar > a {
        font-size: 18px;
    }
}

nav.nav-pills.menubar > a.active,
nav.nav-pills.menubar > a:hover,
nav.nav-pills.menubar > a:focus {
    color: var(--bs-white);
    background-color: inherit;
    border-top: 4px solid var(--bs-white);
    border-radius: 0;
}

@media (min-width: 768px) {
    .menubar {
        display: none;
    }
}

/***********************
CONTENT WRAPPER
***********************/

.content-wrapper {
    min-height: calc(100vh - 58px);
    padding-top: 1rem;
    padding-bottom: 60px;
}

@media (min-width: 768px) {
    .content-wrapper {
        padding-bottom: 80px;
        min-height: calc(100vh);
    }

    .content-wrapper {
        margin-left: 300px;
    }

    body.sidenav-toggled .content-wrapper {
        margin-left: 60px;
    }
}

/**************
BREADCRUMB
**************/

.breadcrumb-item a {
    color: var(--bs-success);
}

.breadcrumb {
    background-color: var(--bs-white);
}

ol.breadcrumb {
    flex-wrap: nowrap;
    overflow-x: auto;
    background-color: #17a2b8;
}

nav.breadcrumb-container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
}

ol.breadcrumb {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 10px 0 rgba(0, 0, 0, 0.4);
    border-radius: 0;
    background-color: #c1c4c7;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
}

@media (min-width: 768px) {
    nav.breadcrumb-container {
        top: 0;
    }
    ol.breadcrumb {
        box-shadow: none;
    }
}

.breadcrumb-item {
    white-space: nowrap;
}
.breadcrumb-item > a {
    color: #343a40;
}
.breadcrumb-item.active {
    color: #ffffff;
}

/* @media (min-width: 768px) { */
.breadcrumb-item.active {
    color: #6c757d;
}
/* } */

.breadcrumb-item + .breadcrumb-item::before {
    color: var(--bs-dark) /*#325e41*/;
    content: ">";
}

/**************
FOOTER
**************/

footer.sticky-footer {
    position: absolute;
    right: 0;
    bottom: -10px;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    background-color: rgba(0, 0, 0, 0.125);
    /*transition: all .3s ease-in-out;*/
    color: var(--bs-dark) !important;
}

@media (min-width: 768px) {
    footer.sticky-footer {
        width: calc(100% - 300px);
    }

    body.sidenav-toggled footer.sticky-footer {
        width: calc(100% - 55px);
    }
}

@media (max-width: 767px) {
    footer.sticky-footer {
        display: none;
    }
}

@media (min-width: 768px) {
}

.footer-links {
    margin-top: 0.5rem;
}

.footer-links > * {
    margin-right: 0.5rem;
    white-space: nowrap;
}

.footer-links:first-of-type > * {
    color: var(--bs-secondary);
}

.footer-links > *:not(:last-child):after {
    margin-left: 0.5rem;
    content: "|";
    color: #000;
}

/**************
NOTIFICATION CENTER
**************/
.notification-side-bar {
    width: 400px;
    position: fixed;
    right: -400px;
    top: 0%;
    height: 100%;
    z-index: 1050;
    background: rgba(255, 255, 255, 1);
    padding: 1rem 0.75rem 2rem;
    border-left: 2px double rgba(0, 0, 0, 0.4);
    overflow-y: auto;
}
.notification-side-bar-toggler {
    position: fixed;
    bottom: 200px;
    right: 0;
    z-index: 1050;
    opacity: 0.5;
}

.notification-side-bar-toggler .fa-chevron-circle-right {
    display: none;
}

.notification-side-bar.toggled {
    right: 0;
}

.notification-side-bar-toggler.toggled {
    opacity: 1;
    right: 400px;
    bottom: 300px;
}

.notification-side-bar-toggler.toggled .fa-chevron-circle-right {
    display: inline-block;
}

.notification-side-bar-toggler.toggled .fa-ellipsis-h {
    display: none;
}

.notification-side-bar-toggler,
.notification-side-bar {
    transition: all 0.6s cubic-bezier(0.29, 0.57, 0.2, 1.4) 0s;
}

@media (min-width: 768px) {
    .notification-side-bar-toggler {
        top: 120px;
        bottom: unset;
    }
    .notification-side-bar-toggler.toggled {
        top: 10px;
        bottom: unset;
    }
}

@media (max-width: 420px) {
    .notification-side-bar {
        width: 90vw;
        right: -90vw;
    }
    .notification-side-bar-toggler.toggled {
        right: 90vw;
    }
}

.notification-side-bar a {
    color: inherit !important;
}
