.dashboard {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    
    @media (max-width: 576px) {
        .row > div {
            padding: 0.25rem !important;
        }
    }

    .upcoming {
        margin-top: 2rem;

        @media (min-width: 576px) {
            margin-top: 3rem;
        }
        
        .card .list-group-item {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
