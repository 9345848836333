:root {
    --events: rgba(45, 54, 147, 1);
    --hw: #e63e32;
    --pcs: #122015;
    --bs-orange: #f08023;
    --bs-orange-shade: #cc6814;
    --grey: #eaeaea;
}

:root body.dark-mode {
    --grey: rgb(30, 30, 30);
    --bs-white: #000000;
    --bs-light: #000000;
    --bs-dark: #fff;
}

body.dark-mode .card, body.dark-mode .card-footer, body.dark-mode {
    background-color: #000000 !important;
}

body.dark-mode .bg-light, body.dark-mode .btn-light {
    background-color: var(--grey) !important;
}

body.dark-mode :not(.banner), body.dark-mode :not(.page-banner), body.dark-mode :not(input), body.dark-mode :not(select), body.dark-mode :not(textarea) {
    color: #fff;
}

body.dark-mode textarea, body.dark-mode select, body.dark-mode input {
    color: #000000 !important;
}

body.dark-mode .card {
    border-color: var(--grey) !important;
}

.list-group-item {
    background-color: var(--bs-white);
}

.mce-content-body {
    padding: .5rem;
}

.font-mansalva {
    font-family: 'Mansalva' !important;
}

.display-4 {
    font-size: 3.5rem;
}

@media(max-width: 567px) {
    .display-4 {
        font-size: 2.5rem;
    }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Noto Sans JP', sans-serif;
}

.row {
    margin: 0;
    padding: 0;
}

.max-1200 {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.max-1000 {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.max-900 {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.max-800 {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.max-700 {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.max-600 {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.max-500 {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

/**************
TEXT COLOURS
**************/

.text-teal {
    color: var(--bs-teal) !important;
}

.text-cyan {
    color: var(--bs-cyan) !important;
}

.text-indigo {
    color: var(--bs-indigo) !important;
}

.text-orange {
    color: var(--bs-orange) !important;
}

.text-pink {
    color: var(--bs-pink) !important;
}

.text-purple {
    color: var(--bs-purple) !important;
}

.text-navy {
    color: var(--bs-navy) !important;
}

.info-msg {
    font-size: 14px;
    line-height: 1;
}

/**************
BACKGROUND COLOURS
*************/

.bg-teal {
    background-color: var(--bs-teal) !important;
}

.bg-cyan {
    background-color: var(--bs-cyan) !important;
}

.bg-indigo {
    background-color: var(--bs-indigo) !important;
}

.bg-orange {
    background-color: var(--bs-orange) !important;
}

.bg-pink {
    background-color: var(--bs-pink) !important;
}

.bg-purple {
    background-color: var(--bs-purple) !important;
}

.bg-grey {
    background-color: var(--grey) !important;
}

.bg-navy {
    background-color: var(--bs-navy) !important;
}

.bg-hw {
    background-color: var(--hw);
}

.bx-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.underlined {
    text-decoration: underline;
}

/**************
border COLOURS
*************/

.border-teal {
    border-color: var(--bs-teal) !important;
}

.border-cyan {
    border-color: var(--bs-cyan) !important;
}

.border-indigo {
    border-color: var(--bs-indigo) !important;
}

.border-orange {
    border-color: var(--bs-orange) !important;
}

.border-pink {
    border-color: var(--bs-pink) !important;
}

.border-purple {
    border-color: var(--bs-purple) !important;
}

.border-grey {
    border-color: var(--grey) !important;
}

.border-navy {
    border-color: var(--bs-navy) !important;
}

/**************
BTN COLORS
**************/

/*.btn {
border-radius: 2rem;
}*/

.btn-hw {
    color: var(--bs-white);
    background-color: var(--hw);
}

.btn-pink {
    background-color: var(--bs-pink);
    color: var(--bs-white);
    border-color: var(--bs-pink);
}

.btn-pink:hover, .btn-pink:focus {
    color: var(--bs-pink);
    background-color: var(--bs-white);
}

.btn-teal {
    background-color: var(--bs-teal);
    color: var(--bs-white);
    border-color: var(--bs-teal);
}

.btn-teal:hover, .btn-teal:focus {
    color: var(--bs-teal);
    background-color: var(--bs-white);
}

.btn-purple {
    background-color: var(--bs-purple);
    color: var(--bs-white);
    border-color: var(--bs-purple);
}

.btn-purple:hover, .btn-purple:focus {
    color: var(--bs-purple);
    background-color: var(--bs-white);
}

.btn-orange {
    background-color: var(--bs-orange);
    color: var(--bs-white);
    border-color: var(--bs-orange);
}

.btn-orange:hover, .btn-pink:focus {
    color: var(--bs-white);
    background-color: var(--orange-shade);
}

.btn-navy {
    background-color: var(--bs-navy);
    color: var(--bs-white);
    border-color: var(--bs-navy);
}

.btn-navy:hover, .btn-navy:focus {
    color: var(--bs-navy);
    background-color: var(--bs-white);
}

/*****OUTLINE****/

.btn-outline-events {
    border-color: var(--events);
    color: var(--events);
    background-color: var(--bs-white);
}

.btn-outline-events:hover, .btn-outline-events:focus {
    color: var(--bs-white);
    background-color: var(--events);
}

.btn-outline-hw {
    border-color: var(--hw);
    color: var(--hw);
    background-color: var(--bs-white);
}

.btn-outline-hw:hover, .btn-outline-hw:focus {
    color: var(--bs-white);
    background-color: var(--hw);
}

.btn-outline-teal {
    border-color: var(--bs-teal);
    color: var(--bs-teal);
    background-color: transparent;
}

.btn-outline-teal:hover, .btn-outline-teal:focus {
    color: var(--bs-white);
    background-color: var(--bs-teal);
}

.btn-outline-pink {
    color: var(--bs-pink);
    background-color: transparent;
    border-color: var(--bs-pink);
}

.btn-outline-pink:hover, .btn-outline-pink:focus {
    background-color: var(--bs-pink);
    color: var(--bs-white);
}

.btn-outline-teal {
    color: var(--bs-teal);
    border-color: var(--bs-teal);
    background-color: transparent;
}

.btn-outline-teal:hover, .btn-outline-teal:focus {
    background-color: var(--bs-teal);
    color: var(--bs-white);
}

.btn-outline-purple {
    color: var(--bs-purple);
    border-color: var(--bs-purple);
    background-color: transparent;
}

.btn-outline-purple:hover, .btn-outline-purple:focus {
    background-color: var(--bs-purple);
    color: var(--bs-white);
}

.btn-outline-orange {
    color: var(--bs-orange);
    border-color: var(--bs-orange);
    background-color: transparent;
}

.btn-outline-orange:hover, .btn-outline-orange:focus {
    background-color: var(--bs-orange);
    color: var(--bs-white);
}

.btn-outline-navy {
    color: var(--bs-navy);
    border-color: var(--bs-navy);
    background-color: transparent;
}

.btn-outline-navy:hover, .btn-outline-navy:focus {
    background-color: var(--bs-navy);
    color: var(--bs-white);
}

.btn-toolbar {
    white-space: nowrap;
}

/* UTILITIES */

.flex-equal>* {
    -ms-flex: 1;
    flex: 1;
}

@media (min-width: 568px) {
    .flex-sm-equal>* {
        -ms-flex: 1;
        -webkit-box-flex: 1;
        flex: 1;
    }
}

@media (min-width: 768px) {
    .flex-md-equal>* {
        -ms-flex: 1;
        -webkit-box-flex: 1;
        flex: 1;
    }
}

.underlined {
    text-decoration: underline;
}

.list-group-item.active {
    background-color: var(--bs-teal);
    border-color: var(--bs-teal);
}

.modal-content {
    border-radius: .75rem;
}